<template>
  <div :loading="loading">
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-carousel
          height="400"
          show-arrows-on-hover
          class="shadow"
          :loading="true"
        >
          <v-carousel-item v-for="(slide, i) in advertisements" :key="i">
            <base-card
              height="100%"
              :href="slide.link"
              target="_blank"
              class="d-flex flex-row justify-center align-center display-1 text-center"
            >
              <div
                :style="
                  `backgroundImage:url(${slide.image.data.full_url}); height: 100%;width: 100%;objectFit:contain`
                "
                class="d-flex flex-column align-center justify-center"
              >
                <div class="display-2 text-center white--text">
                  {{ slide[lang + 'title'] }}
                </div>
                <div
                  class="body-1 text-center my-8 white--text"
                  style="fontSize:20px !important"
                >
                  {{ slide[lang + 'body'] }}
                </div>
              </div>
            </base-card>
          </v-carousel-item>
        </v-carousel>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <div class="shadow">
          <base-card
            height="400px"
            to="/coaching"
            class="display-1 pa-5"
            style="backgroundColor:#F1854D"
          >
            <v-icon color="white" style="fontSize:60px"
              >mdi-lightbulb-on</v-icon
            >
            <div
              style="width: 100%;height: 65%"
              class="d-flex flex-column justify-center align-center"
            >
              <div class="white--text text-center">
                {{ $t('coachingPageTitle') }}
              </div>
            </div>
            <div class="text-left" style="width: 100%">
              <v-icon color="white" style="fontSize: 30px"
                >mdi-arrow-left-circle</v-icon
              >
            </div>
          </base-card>
        </div>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-carousel
          cycle
          height="400"
          show-arrows-on-hover
          class="shadow"
          reverse
        >
          <v-carousel-item v-for="(slide, i) in tags" :key="i">
            <base-card
              height="100%"
              :to="`/posts?type=${slide.id}`"
              class="display-1 pa-5"
              style="backgroundColor:#1C8BC3"
            >
              <v-icon color="white" style="fontSize:60px">mdi-tag</v-icon>
              <div
                style="width: 100%;height: 65%"
                class="d-flex flex-column justify-center align-center"
              >
                <div class="white--text">
                  {{ slide[lang + 'title'] }}
                </div>
              </div>
              <div class="text-left" style="width: 100%">
                <v-icon color="white" style="fontSize: 30px"
                  >mdi-arrow-left-circle</v-icon
                >
              </div>
            </base-card>
          </v-carousel-item>
        </v-carousel>
        <!-- </div> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApiService from '@/services/api.service';
export default {
  data() {
    return {
      loading: false,
      tags: [],
      advertisements: [],
      lang: this.$root.lang == 'en' ? 'en' : '',
    };
  },
  methods: {
    async getTags() {
      try {
        this.loading = true;
        const tags = await ApiService.get(`items/tags?fields=id,title,entitle`);
        const advertisements = await ApiService.get(
          `items/advertisement?fields=*,image.data.full_url&filter[slider]=0`
        );
        this.tags = tags.data.data;
        this.advertisements = advertisements.data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getTags();
  },
};
</script>

<style>
.bg-img {
  background-image: url('https://media2.s-nbcnews.com/i/newscms/2018_39/2582131/180928-differing-personality-mc-1541_82da49880ddb600e0b7d668fd1ab953b.JPG');
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
}

.bg-img .overlay {
  position: 'absolute';
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -100;
}

.shadow {
  box-shadow: 0px 4px 18px #b7cdf1;
  border-radius: 12px;
  /* border: 1px solid #ddd; */
  overflow: hidden;
  /* width: 80%; */
  transition: all 0.3s ease-in-out;
  /* background: #ccb1de !important;
  background: -webkit-linear-gradient(top left, #ccb1de, #89a6c6) !important;
  background: -moz-linear-gradient(top left, #ccb1de, #89a6c6) !important;
  background: linear-gradient(to bottom right, #ccb1de, #89a6c6) !important; */
}

/* .shadow:hover {
  transform: scale(1.1, 1.1);
} */

.v-carousel__controls__item {
  /* background: red; */
}

/* .v-carousel__controls__item span .mdi-circle::after {
  background: #1d8ac3;
} */

.v-carousel__controls__item span .mdi-circle::before {
  color: #1d8ac3;
}
</style>
